<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title= "안전점검 결과 목록"
            :merge="grid.merge"
            :columns="grid.columns"
            :data="grid.data"
            :gridHeight="grid.height"
            rowKey="safetyCheckResultId"
            :editable="!disabled"
            :columnSetting="false"
            :usePaging="false"
            @headLinkClick="headLinkClick"
          >
            <!-- <template slot="suffixTitle">
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-positive"/> : 점검완료 &nbsp;&nbsp;
                <q-icon name="build" class="text-orange"/> : 점검중 &nbsp;&nbsp;
                <q-icon name="error" class="text-red"/> : 금주 미진행 &nbsp;&nbsp;
                <q-icon name="close" class="text-red"/> : 미진행 &nbsp;&nbsp;
              </font>
            </template> -->
            <template slot="prefixContent">
              <font v-if="!data.plantCd || !data.checkDate" color="#C10015">
                ※ 사업장 및 점검년월을 선택하세요.
              </font>
              <!-- <font v-if="this.data.safetyCheckId" color="#03d1fd">
                ※ 각 항목 클릭시 개선요청이 가능합니다.
              </font> -->
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="props.row[col.key] === 'MSR0000001'">
                <q-icon 
                  name="radio_button_unchecked" 
                  :class="props.row[col.key] === 'MSR0000001' ? 'text-positive' : ''"
                  class="cursor-pointer" 
                  @click="openPop(col, props)" />
              </template>
              <template v-else-if="props.row[col.key] === 'MSR0000002'">
                <q-icon 
                  name="close" 
                  :class="props.row[col.key] === 'MSR0000002' ? 'text-red' : ''"
                  class="cursor-pointer" 
                  @click="openPop(col, props)" />
              </template>
            </template>
            <!-- 버튼 영역 -->
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions" :returnData="{ col1: data.safetyCheckId }">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-check-result',
  props: {
    data: {
      type: Object,
      default: () => ({
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        safetyCheckName: '',  // 점검명
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드

        safetyCheckResultModelList: [], // sheet
      }),
    },
    grid: {
      height: 'auto',
      columns: [],
      data: [],
    },
  },
  data() {
    return {
      year: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.safetycheck.plan.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.insertUrl = transactionConfig.sop.safetycheck.plan.insert.url;
      this.updateUrl = transactionConfig.sop.safetycheck.plan.update.url;
      this.deleteUrl = transactionConfig.sop.safetycheck.plan.delete.url;
      this.completeUrl = transactionConfig.sop.safetycheck.plan.complete.url;
      this.resultItemDetail = selectConfig.sop.safetycheck.result.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    headLinkClick(props, col, index) {
      let realData = [];
      this.popupOptions.param = {
        col: col,
        data: this.data,
      };
      this.popupOptions.title = '안전점검 결과 상세'; 
      this.popupOptions.target = () =>
        import(`${"./safetyCheckChecking.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '99%';
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(returnData) {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (returnData.col1) {
        this.data.safetyCheckId = returnData.col1;
        this.$emit('setKey', returnData.col1)
      } else {
        this.$emit('getDetail')
      }
    },
  }
};
</script>
